<ion-header class="ion-no-border border-bottom-cloudotdr">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="primary" />
    </ion-buttons>
    <ion-title>
      <div class="pageTitle">{{ this.pageTitle() }}</div>
      <div class="deploymentName">{{ this.deploymentStore.selectedDeployment()?.name }}</div>
    </ion-title>

    <div class="ion-margin-end" slot="end">
      @if (this.notificationStore.signalRConnectionState() === 1) {
        <ion-icon name="wifi-outline" color="warning"></ion-icon>
      } @else if (this.notificationStore.signalRConnectionState() === 2) {
        <ion-icon name="wifi-outline" color="success"></ion-icon>
      } @else {
        <ion-icon name="wifi-outline" color="danger"></ion-icon>
      }
    </div>
  </ion-toolbar>
</ion-header>
