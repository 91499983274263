import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Tenant } from '@/app/interfaces/api/tenant';
import { TenantEdit } from '@/app/interfaces/api/tenant-edit';
import { Feature } from '@/app/interfaces/api/tenant-feature';
import { environment } from '@/environments/environment';
import { map } from 'rxjs/operators';
import { Hierarchical } from '@/shared/lib/core/utils/hierarchical';

@Injectable({
  providedIn: 'root'
})
export class TenantsApi {
  http = inject(HttpClient);

  public getTenantByHeader(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${environment.data.baseUrl}/tenants/byheader`).pipe(map(result => {
      return result && result.length
        ? Hierarchical.sortByLevel(result)
        : result;
    }));
  }

}
