<ng-container class="fullwidth">
  <ion-list class="margin-left-right-0 margin-top-bottom-10" [inset]="true">
    <ion-item [button]="true" [detail]="false" (click)="openModal()">
      <ion-label class="single-select-header">
        @if (isTenantSelection()) {
          Tenant:
        } @else {
          Deployment:
        }
      </ion-label>
      <div id="selected-value" class="single-select-value" slot="end">
        @if (isTenantSelection()) {
          {{this.deploymentStore.selectedTenant()?.name}}
        } @else {
          {{this.deploymentStore.selectedDeployment()?.name}}
        }
      </div>
      <div id="selected-value-icon" class="single-select-value" slot="end">
        <ion-icon name="chevron-down-outline"></ion-icon>
      </div>
    </ion-item>
  </ion-list>
</ng-container>
