import { ChangeDetectionStrategy, Component, computed, inject, NgZone, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { addIcons } from 'ionicons';
import { logOutOutline } from 'ionicons/icons';
import { filter } from 'rxjs';

import { ScanType } from '@/app/constants/scan-type.enum';
import { TenantDeploymentPage } from '@/app/modals/tenant-deployment/tenant-deployment.page';
import { SupportedVersionService } from '@/app/services/app/supported-version.service';
import { environment } from '@/environments/environment';
import { isNotNullOrEmpty, isNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { MessageService } from '@/shared/lib/services/communications/message.service';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { NotificationStore } from '@/shared/lib/stores/notification.store';
import { UserStore } from '@/shared/lib/stores/user.store';
import { NgOptimizedImage } from '@angular/common';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { DataStore } from '@/shared/lib/stores/data.store';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ods-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [CoreModule, RouterModule, NgOptimizedImage],
})
export class AppComponent {
  public scanTypeEnum: typeof ScanType = ScanType;
  public isDevelopment = computed(() => !environment.production);
  public selectedAction = signal<string>('');

  userStore = inject(UserStore);
  deploymentStore = inject(DeploymentStore);
  notificationStore = inject(NotificationStore);
  dataStore = inject(DataStore);
  userObs = toObservable(this.userStore.isAuthenticated);

  zone = inject(NgZone);
  platform = inject(Platform);
  message = inject(MessageService);
  menu = inject(MenuController);
  router = inject(Router);
  translate = inject(TranslateService);
  modalController = inject(ModalController);
  supportedVersionService = inject(SupportedVersionService);

  constructor() {
    addIcons({
      'log-out-outline': logOutOutline,
    });
    this.translate.setDefaultLang('en');

    this.platform.ready().then(() => {
      this.initializeApp();
    });
    this.platform.resume.subscribe(() => {
      this.zone.run(() => {
        this.reInitializeApp();
      });
    });

    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      )
      .subscribe(event => {
        if (event && event.url) {
          const currentUrl = event.url.split('/');

          this.selectedAction.set(currentUrl[currentUrl.length - 1]);
        }
      });
  }

  initializeApp() {
    SplashScreen.show().then(() => {
      SplashScreen.hide().then(() => {
        this.loadCoreData();
      });
    });
  }

  reInitializeApp() {
    SplashScreen.show().then(() => {
      SplashScreen.hide().then(() => {
        if (this.userStore.isAuthenticated()) {
          this.loadCoreData();
        } else {
          this.router.navigate(['/login']);
        }
      });
    });
  }

  loadCoreData() {
    this.loadDeviceInfo();
    this.userObs.subscribe((authStatus: boolean) => {
      if (authStatus) {
        this.notificationStore.setSignalRConnectionState(1, 'loadCoreData');
        this.deploymentStore.loadTenantList(false);
        this.translate.setDefaultLang(this.userStore.displayLanguage());
        this.supportedVersionService.getSupportedVersion();
      }
    });
  }

  async loadDeviceInfo() {
    if (Device) {
      Device.getInfo().then(x => {
        this.dataStore.setDeviceInfo(x);
        if (x.platform !== 'web') {
          App.getInfo().then(appInfo => this.dataStore.setAppInfo(appInfo));
        }
      });
    }
  }

  closeMenu() {
    this.menu.close();
  }

  public logOut() {
    this.userStore.clear();
    this.deploymentStore.clear();
    this.notificationStore.clear();
    window.location.reload();
  }

  async presentTenantDeploymentModal() {
    const modal = await this.modalController.create({
      component: TenantDeploymentPage,
      cssClass: 'settingsModal',
      canDismiss: true,
      componentProps: {},
    });
    return await modal.present();
  }

  protected readonly isNotNullOrEmpty = isNotNullOrEmpty;
  protected readonly isNullOrEmpty = isNullOrEmpty;
}
