import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTransformPipe',
  standalone: true,
})
export class DateFormatExtension implements PipeTransform {

  constructor(public datepipe: DatePipe) {
  }

  transform(inputDate: any, format = 'yyyy-MM-dd HH:mm:ss'): string | undefined | null {
    if (inputDate != null) {
      if (inputDate === 'now') {
        const newDate = new Date();
        return this.datepipe.transform(newDate, format);
      } else if (inputDate.slice(-1) !== 'Z') {
        inputDate = inputDate + 'Z';
      }

      return this.datepipe.transform(new Date(inputDate), format)?.toString();
    }

    return null;
  }
}
