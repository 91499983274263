import { ChangeDetectionStrategy, Component, inject, input, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { SearchListComponent } from '@/app/modals/search-list/search-list.component';
import { addIcons } from 'ionicons';
import { chevronDownOutline, refreshOutline } from 'ionicons/icons';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ods-single-select',
    templateUrl: './single-select.component.html',
    imports: [CoreModule]
})
export class SingleSelectComponent {
  @ViewChild(IonModal) modal: IonModal = {} as IonModal;
  isTenantSelection = input<boolean>(true);

  deploymentStore = inject(DeploymentStore);
  modalController = inject(ModalController);

  constructor() {
    addIcons({
      'chevron-down-outline': chevronDownOutline,
      'refresh-outline': refreshOutline,
    });
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: SearchListComponent,
      canDismiss: true,
      componentProps: {
        isTenantSelection: this.isTenantSelection(),
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.role === 'confirm') {
        if (isNotNullOrEmpty(data?.data)) {
          if (this.isTenantSelection()) {
            this.deploymentStore.setTenant(data.data, false);
          } else {
            this.deploymentStore.setDeployment(data.data, false);
          }
        }
      }
    });
    return await modal.present();
  }

  refreshData() {
    if (this.isTenantSelection()) {
      this.deploymentStore.loadTenantList(true);
    } else {
      this.deploymentStore.loadDeploymentList(true);
    }
  }

}
