import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listToValue',
  standalone: true,
})
export class ListToValueExtension implements PipeTransform {
    transform(value: any, dataList: any, valueField: string, returnField: string): string {
        const foundItem = dataList.find((item: any) => item[valueField] === value);

        if (foundItem) {
            return foundItem[returnField];
        }

        return '';
    }
}
