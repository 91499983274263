import { Component, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { SingleSelectComponent } from '@/app/components/single-select/single-select.component';
import { OdsLoaderComponent } from '@/app/components/ods-loader/ods-loader.component';

@Component({
  selector: 'ods-tenant-deployment',
  templateUrl: './tenant-deployment.page.html',
  imports: [CoreModule, SingleSelectComponent, OdsLoaderComponent],
})

export class TenantDeploymentPage {
  protected modalController = inject(ModalController);
  protected deploymentStore = inject(DeploymentStore);

  closeModal() {
    this.modalController.dismiss();
  }
}
