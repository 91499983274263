import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scanTypeTranslationReference',
  standalone: true,
})
export class ScanTypeTranslationReferenceExtension implements PipeTransform {
  transform(scanType: number): string {
    switch (scanType) {
      case 1: {
        return 'pages.history.scanType.scan.long';
      }
      case 2: {
        return 'pages.history.scanType.connectorCheck.long';
      }
      case 3: {
        return 'pages.history.scanType.aux.long';
      }
      case 4: {
        return 'pages.history.scanType.laser.long';
      }
      case 99: {
        return 'pages.history.scanType.stop.long';
      }
      case 101: {
        return 'pages.history.scanType.monitoring.long';
      }
      case 102: {
        return 'pages.history.scanType.monitoringStop.long';
      }
      case 131: {
        return 'pages.history.scanType.monitoringReset.long';
      }
      case 201: {
        return 'pages.history.scanType.sor-analyzer.long';
      }
      default: {
        return '';
      }
    }
  }
}
