import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monitoringStatusText',
  standalone: true,
})
export class MonitoringStatusTextExtension implements PipeTransform {
  transform(status: boolean): string {
    const messageColor = status ? 'success' : 'warning';
    const text = status ? 'Ok' : 'Alert';

    return `<span class="${messageColor}">${text}</span>`;
  }
}
