import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { apiEndpoint } from '@/app/constants/endpoints.const';
import { VersionInfo } from '@/app/interfaces/app/version-info';

@Injectable({
  providedIn: 'root'
})
export class SupportVersionApi {
  http = inject(HttpClient);

  public getSupportVersion(): Observable<VersionInfo> {
    return this.http.get<VersionInfo>(`${apiEndpoint}/supported-versions`);
  }

}
