export const environment = {
  production: false,

  identity: {
    baseUrl: 'https://api.cloudotdr.com/auth/v1',
    gatewayKey: '5caab2b1e71c4310b90fd4d1174d02fc',
    unprotectedCalls: ['LoginJwt', 'ForgotCredentials'],
  },

  data: {
    // baseUrl: 'http://localhost:7071/api',
    baseUrl: 'https://dev-api.cloudotdr.com/mobile/v2',
    gatewayKey: '1ac71e353962437a94a58f4aa8dcb657',
  },

  numberOfDaysOfHistory: 100,
  environmentStoragePrefix: 'dev_',
};
