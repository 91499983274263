import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Deployment } from '@/app/interfaces/api/deployment';
import { DeploymentEdit } from '@/app/interfaces/api/deployment-edit';
import { DeploymentNotifications } from '@/app/interfaces/api/deployment-notification';
import { DeploymentRemoteSetting } from '@/app/interfaces/api/deployment-remote-setting';
import { environment } from '@/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeploymentsApi {
  http = inject(HttpClient);

  public getDeploymentDataFromApi(tenant: number): Observable<Deployment[]> {
    return this.http.get<Deployment[]>(`${environment.data.baseUrl}/deployments/extended`, { params: { tenantId: `${tenant}` } });
  }
}
