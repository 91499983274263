import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { NotificationStore } from '@/shared/lib/stores/notification.store';
import { addIcons } from 'ionicons';
import { wifiOutline } from 'ionicons/icons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
  standalone: true,
  imports: [IonicModule],
})

export class PageHeaderComponent {
  pageTitle = input<string>('cloudOTDR');

  deploymentStore = inject(DeploymentStore);
  notificationStore = inject(NotificationStore);

  constructor() {
    addIcons({
      'wifi-outline': wifiOutline,
    });
  }
}
