import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NotificationStore } from '@/shared/lib/stores/notification.store';
import { addIcons } from 'ionicons';
import { checkmarkDoneOutline, syncOutline } from 'ionicons/icons';
import { OdsLoaderComponent } from '@/app/components/ods-loader/ods-loader.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'notification-progress',
  templateUrl: './notification-progress.component.html',
  styleUrl: './notification-progress.component.scss',
  standalone: true,
  imports: [IonicModule, NgForOf, OdsLoaderComponent],
})

export class NotificationProgressComponent {
  notificationStore = inject(NotificationStore);

  constructor() {
    addIcons({
      'checkmark-done-circle-outline': checkmarkDoneOutline,
      'sync-outline': syncOutline,
    });
  }
}
