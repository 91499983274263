export class ActionOtdr {
     SamplingRate: boolean = false;
    MeasurementMode: boolean = false;
    FiberEndDetection: boolean = false;
    LossDetection: boolean = false;
    ReflectanceDetection: boolean = false;
    LaunchFiberStart: boolean = false;
    LaunchFiberEnd: boolean = false;
    Wavelength: boolean = false;
}
