import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';

import { SupportVersionApi } from '@/app/services/api/support-version.api';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { NotificationStore } from '@/shared/lib/stores/notification.store';

import { NotificationType } from '../../constants/notification.enum';
import { VersionInfo } from '../../interfaces/app/version-info';
import { NotificationService } from '../communications/notification.service';

@Injectable({
  providedIn: 'root',
})
export class SupportedVersionService {
  notificationService = inject(NotificationService);
  supportVersionApi = inject(SupportVersionApi);
  http = inject(HttpClient);
  notificationStore = inject(NotificationStore);

  public getSupportedVersion() {
    if (Device) {
      Device.getInfo().then(x => {
        if (x.platform !== 'web') {
          this.supportVersionApi.getSupportVersion().subscribe(supportedVersionApi => {
            if (isNotNullOrEmpty(supportedVersionApi) && this.notificationStore.userVersionAlreadyNotified(supportedVersionApi)) {
              return;
            }

            App.getInfo().then(appInfo => {
              let updateUrl = '';
              if (x.platform === 'ios') {
                updateUrl = `itms-apps://itunes.apple.com/app/cloudotdr/id1550732783`;
              } else if (x.platform === 'android') {
                updateUrl = `https://play.google.com/store/apps/details?id=${appInfo.id}`;
              }

              const versionData = appInfo?.version.split('.');
              const appVersion: VersionInfo = {
                major: parseInt(versionData[0], 0),
                minor: parseInt(versionData[1], 0),
                patch: parseInt(versionData[2], 0),
              };

              if (isNotNullOrEmpty(supportedVersionApi) && isNotNullOrEmpty(appVersion)) {
                if (supportedVersionApi.major > appVersion.major) {
                  this.notificationService.presentToast('A newer version of the app is available. Please update the app to the latest version.', NotificationType.Success, updateUrl).then(() => {
                  });
                  this.notificationStore.setLastVersionInfo(supportedVersionApi);
                } else {
                  if (supportedVersionApi.minor > appVersion.minor) {
                    this.notificationService.presentToast('A newer version of the app is available. Please update the app to the latest version.', NotificationType.Success, updateUrl).then(() => {
                    });
                    this.notificationStore.setLastVersionInfo(supportedVersionApi);
                  } else {
                    if (supportedVersionApi.patch > appVersion.patch) {
                      this.notificationService.presentToast('A newer version of the app is available. Please update the app to the latest version.', NotificationType.Success, updateUrl).then(() => {
                      });
                      this.notificationStore.setLastVersionInfo(supportedVersionApi);
                    } else {
                      this.notificationStore.setLastVersionInfo(supportedVersionApi);
                    }
                  }
                }
              }
            });
          });
        }
      });
    }
  }
}
