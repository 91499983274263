import { computed, effect, inject } from '@angular/core';
import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { EnabledFeatures } from '@/shared/lib/interfaces/enabled-features';
import { ScanType } from '../../../../app/src/app/constants/scan-type.enum';
import { Deployment } from '../../../../app/src/app/interfaces/api/deployment';
import { Tenant } from '../../../../app/src/app/interfaces/api/tenant';
import { DeploymentsApi } from '../../../../app/src/app/services/api/deployments.api';
import { DevicesApi } from '../../../../app/src/app/services/api/devices.api';
import { PreferencesApi } from '../../../../app/src/app/services/api/preferences.api';
import { SignalRService } from '../../../../app/src/app/services/communications/signal-r.service';
import { storagePrefix } from '../constants/storage.const';
import { Configuration } from '../../../../app/src/app/interfaces/api/configuration';
import { TenantsApi } from '../../../../app/src/app/services/api/tenants.api';
import { UserStore } from '@/shared/lib/stores/user.store';
import { Hierarchical } from '@/shared/lib/core/utils/hierarchical';

type DeploymentState = {
  selectedTenant: Tenant | null;
  tenantList: Tenant[] | null;
  selectedDeployment: Deployment | null;
  deploymentList: Deployment[] | null;
  deploymentIsLoading: boolean;
  preferences: Configuration | null;
};

const initialState: DeploymentState = {
  selectedTenant: null,
  tenantList: [],
  selectedDeployment: null,
  deploymentList: [],
  deploymentIsLoading: false,
  preferences: null,
};

export const DeploymentStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => {
    return {
      selectedTenantId: computed(() => {
        return store.selectedTenant()?.id;
      }),
      selectedDeploymentId: computed(() => {
        return store.selectedDeployment()?.id;
      }),
      tenantFeatureList: computed(() => {
        const tenantFeature: EnabledFeatures =
          {
            scan: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'scan') ?? false,
            connectorCheck: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'connectorcheck') ?? false,
            history: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'history') ?? false,
            aux: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'aux') ?? false,
            vfl: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'vfl') ?? false,
            monitoring: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'monitoring') ?? false,
            sorConverter: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'sorconverter') ?? false,
          };
        return tenantFeature;
      }),
      deploymentFiberList: computed(() => {
        return store.selectedDeployment()?.deploymentPortMappings ?? [];
      }),
      deploymentLaunchFiberBundleArray: computed(() => {
        const launchFiberArray: any[] = [];

        store.selectedDeployment()?.deploymentPortMappings?.forEach((fiber: any) => {
          const launchFiber = Math.ceil(fiber.fiberNumber / 12);
          if (!launchFiberArray.find(f => f === launchFiber)) {
            launchFiberArray.push(Math.ceil(fiber.fiberNumber / 12));
          }
        });

        return launchFiberArray.sort((a, b) => a > b ? 1 : -1);
      }),
    };
  }),
  withComputed((store) => ({
    scanTypeList: computed<any[]>
    (() => {
      const scanTypeEnum: typeof ScanType = ScanType;
      const features: EnabledFeatures = store.tenantFeatureList();
      if (isNotNullOrEmpty(features)) {

        const scanTypeList: any[] = [];
        const enumList = Object.values(ScanType).filter(value => typeof value === 'number');
        for (const option of enumList) {
          if (Number(option) === scanTypeEnum.Scan && features.scan) {
            scanTypeList.push(option);
          }
          if (Number(option) === scanTypeEnum.VisualFaultLocator && features.vfl) {
            scanTypeList.push(option);
          }
          if (Number(option) === scanTypeEnum.Aux && features.aux) {
            scanTypeList.push(option);
          }
          if (Number(option) === scanTypeEnum.MonitoringStart && features.monitoring) {
            scanTypeList.push(option);
          }
          if (Number(option) === scanTypeEnum.ConnectorCheck && features.connectorCheck) {
            scanTypeList.push(option);
          }
        }

        return scanTypeList;
      }
      return [];
    }),
  })),
  // General
  withMethods((store, signalRService = inject(SignalRService)) => ({
    clear() {
      patchState(store, initialState);
    },
    subscribeSignalR(source: string = '') {
      signalRService.unsubscribeActiveHubs(source);
      if (isNotNullOrEmpty(store.selectedDeploymentId())) {
        signalRService.subscribeToDeploymentHub(store.selectedDeploymentId()!, source);
      }
    },
    patchConfiguration(configuration: Configuration) {
      patchState(store, { preferences: configuration });
    },
  })),
  // Deployments
  withMethods((store, deploymentsApi = inject(DeploymentsApi), devicesApi = inject(DevicesApi)) => ({
    loadDeploymentList(reload: boolean) {
      if (isNotNullOrEmpty(store.selectedTenantId())) {
        deploymentsApi.getDeploymentDataFromApi(store.selectedTenantId()!).subscribe((deployments: any) => {
          patchState(store, { deploymentList: deployments, deploymentIsLoading: false });
          if (isNotNullOrEmpty(deployments)) {
            this.setDeploymentList(deployments, reload);
          } else {
            patchState(store, { selectedDeployment: null });
          }
        });
      }
    },
    setDeploymentList(deploymentList: Deployment[], reload: boolean) {
      if (deploymentList.length === 1) {
        this.setDeployment(deploymentList[0].id, reload);
      } else if (isNotNullOrEmpty(store.selectedDeploymentId())) {
        this.setDeployment(store.selectedDeploymentId()!, reload);
      }
    },
    setDeployment(deployment: number, reload: boolean) {
      if (isNotNullOrEmpty(deployment)) {
        if (store.selectedDeploymentId() !== deployment || reload) {
          const deploymentFromList = JSON.parse(JSON.stringify(store.deploymentList()?.find((d) => d.id === deployment)));
          if (isNotNullOrEmpty(deploymentFromList)) {
            devicesApi.getDeviceConfigurationsExtendedByDeviceId(deploymentFromList.device?.id).subscribe((deviceConfiguration: any) => {
              deploymentFromList.deviceSupportedConfigurations = deviceConfiguration;
              patchState(store, { selectedDeployment: deploymentFromList });
            });
          }
        }
      } else {
        patchState(store, { selectedDeployment: null });
      }
      store.subscribeSignalR('setDeployment');
    },
  })),
  // Tenants
  withMethods((store, preferencesApi = inject(PreferencesApi), tenantsApi = inject(TenantsApi)) => ({
    loadTenantList(reload: boolean) {
      tenantsApi.getTenantByHeader().subscribe((data: any) => {
        if (isNotNullOrEmpty(data)) {
          this.setTenantList(data, reload);
        }
      });
    },
    setTenantList(tenantList: Tenant[], reload: boolean) {
      patchState(store, { tenantList });
      if (tenantList.length === 1) {
        if (isNotNullOrEmpty(tenantList[0].id)) {
          this.setTenant(tenantList[0].id, reload);
        }
      } else if (isNotNullOrEmpty(store.selectedTenantId()) && reload) {
        this.setTenant(store.selectedTenantId()!, reload);
      }
    },
    setTenant(tenant: number, reload: boolean) {
      if (store.selectedTenantId() !== tenant || reload) {
        patchState(store, {
          selectedTenant: store.tenantList()?.find((t) => t.id === tenant),
          selectedDeployment: reload ? store.selectedDeployment() : null,
          deploymentList: [],
          deploymentIsLoading: true,
        });

        preferencesApi.getPreferencesFromApi(store.selectedTenantId()!).subscribe((preferences) => {
          store.patchConfiguration(preferences);
        });
        store.loadDeploymentList(reload);
      }
    },
  })),
  withHooks({
    onInit(store) {
      const signalRService = inject(SignalRService);
      const userStore = inject(UserStore);
      effect(() => {
        const state = getState(store);
        if (isNotNullOrEmpty(state.selectedDeployment?.id) && userStore.isAuthenticated()) {
          signalRService.initialize().then(() => store.subscribeSignalR('onInit'));
        }
      });
    },
  }),
  withStorageSync({
    key: `${storagePrefix}_mobile_deployment`,
    storage: () => localStorage,
  }),
);
