import { AsyncPipe, CommonModule, NgForOf, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import {
  NotificationProgressComponent,
} from '../../../../app/src/app/components/notification-progress/notification-progress.component';
import { PageHeaderComponent } from '../../../../app/src/app/components/page-header/page-header.component';
import { DateExtension } from '../../../../app/src/app/services/extensions/date.extension';
import { DateFormatExtension } from '../../../../app/src/app/services/extensions/datepipe.extension';
import { ListToValueExtension } from '../../../../app/src/app/services/extensions/list-to-value.extension';
import {
  MonitoringStatusIconExtension,
} from '../../../../app/src/app/services/extensions/monitoring/monitoring-status-icon.extension';
import {
  MonitoringStatusTextExtension,
} from '../../../../app/src/app/services/extensions/monitoring/monitoring-status-text.extension';
import { ScanTypeIconExtension } from '../../../../app/src/app/services/extensions/Scan/scan-type-icon.extension';
import {
  ScanTypeTranslationReferenceExtension,
} from '../../../../app/src/app/services/extensions/Scan/scan-type-translation-reference.extension';
import { OdsLoaderComponent } from '../../../../app/src/app/components/ods-loader/ods-loader.component';


@NgModule({
  imports: [
    AsyncPipe,
    DateFormatExtension,
    ListToValueExtension,
    MonitoringStatusIconExtension,
    MonitoringStatusTextExtension,
    NgForOf,
    NgIf,
    NotificationProgressComponent,
    PageHeaderComponent,
    ScanTypeIconExtension,
    ScanTypeTranslationReferenceExtension

  ],
  exports: [
    AsyncPipe,
    CommonModule,
    DateFormatExtension,
    FormsModule,
    IonicModule,
    ListToValueExtension,
    MonitoringStatusIconExtension,
    MonitoringStatusTextExtension,
    NgForOf,
    NgIf,
    NotificationProgressComponent,
    PageHeaderComponent,
    ReactiveFormsModule,
    ScanTypeIconExtension,
    ScanTypeTranslationReferenceExtension,
    TranslateModule,
  ],
  providers: [
    DateExtension,
  ]
})
export class CoreModule { }
