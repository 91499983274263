<ion-header translucent>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="secondary" (click)="closeModal()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-card class="ion-no-margin">
    <ion-card-content>
      <ion-row>
        <ion-col>
          <ion-list class="ion-no-margin">
              <ods-single-select
                class="fullwidth"
                [isTenantSelection]="true"
              >
              </ods-single-select>
              @if (!this.deploymentStore.deploymentIsLoading()) {
                <ods-single-select
                  class="fullwidth"
                  [isTenantSelection]="false"
                >
                </ods-single-select>
              } @else {
                <ods-loader class="ods-spinner-medium"></ods-loader>
              }
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</ion-content>
