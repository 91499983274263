import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class DateExtension {

  constructor(public datepipe: DatePipe) {
  }

  formatDateFromString(inputDate: any, format: string = 'yyyy-MM-dd HH:mm:ss') {
    if (isNotNullOrEmpty(inputDate)) {
      if (inputDate.slice(-1) !== 'Z') {
        inputDate = inputDate + 'Z';
      }

      return this.datepipe.transform(new Date(inputDate), format);
    }
    return;
  }

  formatDateFromDateTime(inputDate: any) {
    return new Date(inputDate).toLocaleString();
  }

  formatDateFromStringToLocale(inputDate: any) {
    if (isNotNullOrEmpty(inputDate)) {
      if (inputDate.slice(-1) === 'Z') {
        return new Date(inputDate).toLocaleString();
      }

      return new Date(inputDate + 'Z').toLocaleString();

    }
    return;
  }

  formatDateFromDateTimeToLocale(inputDate: any) {
    return new Date(inputDate).toLocaleString();
  }

  addDaysToTodayAsDateString(numberOfDays: number) {
    return this.datepipe.transform(new Date(new Date().setDate(new Date().getDate() + numberOfDays)), 'yyyy-MM-dd');
  }
}
