import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';

import { SignalRApi } from '@/app/services/api/signal-r.api';
import { environment } from '@/environments/environment';
import { isNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { MessageService } from '@/shared/lib/services/communications/message.service';
import { NotificationStore } from '@/shared/lib/stores/notification.store';

import { SignalRConnectionInfo } from '../../interfaces/account/signal-r-connection-info';

@Injectable({
  providedIn: 'root',
})

export class SignalRService {
  private hubConnection: HubConnection | undefined;
  private signalRConnectionInfo: SignalRConnectionInfo | undefined;
  private activeHubList: string[] = [];
  private isInitializing = false;

  message = inject(MessageService);
  http = inject(HttpClient);
  notificationStore = inject(NotificationStore);
  signalRApi = inject(SignalRApi);

  public initialize() {
    return new Promise<boolean>((resolve) => {
      if (!this.isInitializing && isNullOrEmpty(this.hubConnection)) {
        this.isInitializing = true;
        this.signalRApi.getSignalRToken().subscribe(result => {
          this.message.add('SignalR.initialize()', 'gotToken');
          this.signalRConnectionInfo = result;
          this.startSignalR().then(() => {
            this.isInitializing = false;
            this.resubscribeActiveHubs();

            resolve(this.hubConnection?.state === signalR.HubConnectionState.Connected);
          });
        });
      } else {
        resolve(true);
      }
    });
  }

  public async startSignalR(source: string = 'startSignalR'): Promise<void> {
    this.notificationStore.setSignalRConnectionState(0, source);
    await this.stopSignalR();
    this.notificationStore.setSignalRConnectionState(1, source);

    const options: signalR.IHttpConnectionOptions = {
      accessTokenFactory: () => this.signalRConnectionInfo?.accessToken ?? '',
    };

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.signalRConnectionInfo?.url ?? '', options)
      .configureLogging(environment.production ? signalR.LogLevel.Error : signalR.LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    await this.hubConnection.start().then(() => {
      this.notificationStore.setSignalRConnectionState(2, source);
    });

    this.hubConnection.onclose(() => {
      this.notificationStore.setSignalRConnectionState(0, source);

      if (this.hubConnection) {
        this.hubConnection
          .start()
          .then(() => {
            this.resubscribeActiveHubs();
            this.message.add('startSignalR', 'Hub Connection Restarted.');
            this.notificationStore.setSignalRConnectionState(2, source);
          })
          .catch(err => {
            this.message.error('startSignalR', err);
            this.notificationStore.setSignalRConnectionState(0, source);
          });
      }
    });
  }

  public async stopSignalR(source: string = 'stopSignalR'): Promise<void> {
    this.notificationStore.setSignalRConnectionState(0, source);
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.hubConnection.off('notify');
      return await this.hubConnection.stop();
    }
    return;
  }

  public subscribeToDeploymentHub(deploymentId: number, source: string = 'subscribeToDeploymentHub') {
    const hubName = `deployment_${deploymentId}`;
    if (isNullOrEmpty(this.activeHubList.find(f => f === hubName))) {
      this.initialize().then(() => this.onHub(hubName, source));
    }
    this.notificationStore.setSignalRConnectionState(2, source);
  }

  public unsubscribeActiveHubs(source: string = 'unsubscribeActiveHubs') {
    this.activeHubList.forEach((hub) => {
      this.hubConnection?.off(hub);
    });

    this.activeHubList = [];
    this.notificationStore.setSignalRConnectionState(0, source);
  }

  private resubscribeActiveHubs(source: string = 'resubscribeActiveHubs') {
    this.notificationStore.setSignalRConnectionState(0, source);
    this.activeHubList.forEach((hub) => {
      this.message.add('resubscribeActiveHubs', hub);
      this.hubConnection?.off(hub);
      this.onHub(hub);
    });
    this.notificationStore.setSignalRConnectionState(2, source);
  }

  private onHub(hubName: string, source: string = 'onHub'): void {
    if (this.activeHubList.find(f => f === hubName) === undefined) {
      this.hubConnection?.on(hubName, data => {
        this.notificationStore.setSignalRConnectionState(2, source);
        this.notificationStore.setActionUnread(data);
      });
      this.activeHubList.push(hubName);
    }
  }
}
