<svg id="ods-loader" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 254 254">
  <defs>
    <style>
      .basic {
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
        stroke: #fff;
        stroke-width: 8px;
      }

      /*.cls-17 {*/
      /*  stroke: #FF3600;*/
      /*}*/

      .dot-1 {
        stroke: #FF3600;
      }
    </style>
  </defs>
  <g id="Layer_1" data-name="Layer 1">
    <g>
      <path class="basic"
            d="M5.77,107.26l71.38.37c10.75,0,19.55,8.8,19.55,19.55h0c0,10.75-8.8,19.55-19.55,19.55l-71.38-.37" />
      <g id="lines">
        <g id="line-1" data-name="line-1">
          <line class="basic cls-11" x1="128.87" y1="111.05" x2="108.9" y2="90.23" />
        </g>
        <g id="line-2" data-name="line-2">
          <line class="basic cls-12" x1="147.1" y1="105.09" x2="150.93" y2="76.5" />
        </g>
        <g id="line-3" data-name="line-3">
          <line class="basic cls-13" x1="163.12" y1="115.63" x2="187.86" y2="100.8" />
        </g>
        <g id="line-4" data-name="line-4">
          <line class="basic cls-14" x1="164.87" y1="134.74" x2="191.89" y2="144.83" />
        </g>
        <g id="line-5" data-name="line-5">
          <line class="basic cls-15" x1="151.03" y1="148.01" x2="159.98" y2="175.43" />
        </g>
        <g id="line-6" data-name="line-6">
          <line class="basic cls-16" x1="132.02" y1="145.47" x2="116.16" y2="169.56" />
        </g>
        <g id="line-7" data-name="line-7">
          <line class="basic cls-17" x1="108.02" y1="128.47" x2="128.87" y2="128.47" />
        </g>
        <g id="circle-1" data-name="circle-1">
          <circle class="basic dot-1" cx="145" cy="127.02" r="4"/>
        </g>
      </g>
    </g>
  </g>
</svg>
