import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monitoringStatusIcon',
  standalone: true,
})
export class MonitoringStatusIconExtension implements PipeTransform {
  transform(status: boolean): string {
    return status ? 'checkmark-done-outline' : 'alert-outline';
  }
}
