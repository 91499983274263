export enum ScanType {
    All = 0,
    Scan = 1,
    ConnectorCheck = 2,
    Aux = 3,
    VisualFaultLocator = 4,
    StopAll = 99,
    MonitoringStart = 101,
    MonitoringStop = 102,
    MonitoringPause = 121,
    MonitoringResume = 122,
    MonitoringAlertReset = 131,
    SorConverter = 201,
}
