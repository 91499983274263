import { inject, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { NotificationType } from '../../constants/notification.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  toast: HTMLIonToastElement | undefined;
  toastController = inject(ToastController);

  public async presentToast(toastMessage?: string, notificationType?: NotificationType, buttonClickOpenUrl?: string) {
    let toastClass: string;
    switch (notificationType) {
      case NotificationType.Error:
        toastClass = 'danger';
        break;
      case NotificationType.Warning:
        toastClass = 'warning';
        break;
      case NotificationType.Success:
        toastClass = 'success';
        break;
      default:
        toastClass = 'success';
        break;
    }

    const toastButtonArray = isNotNullOrEmpty(buttonClickOpenUrl) ? [
        {
          text: 'Update',
          role: 'update',
          handler: () => {
            if (isNotNullOrEmpty(buttonClickOpenUrl)) {
              window.open(buttonClickOpenUrl, '_system');
            }
          },
        },
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
          },
        },
      ] :
      [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
          },
        },
      ];

    this.toast = await this.toastController.create({
      message: toastMessage,
      color: toastClass,
      cssClass: 'toastFullWidth',
      position: 'bottom', //notificationType === NotificationType.Error ? 'middle' : 'bottom',
      duration: notificationType === NotificationType.ShortNotification ? 1000 : 2500000,
      buttons: toastButtonArray,
    });

    this.toast.present();
  }
}
