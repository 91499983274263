<ng-container class="fullwidth">
  <ion-list class="margin-left-right-0 margin-top-bottom-10" [inset]="true">
    <ion-item [button]="true" [detail]="false" (click)="openModal()">
      <ion-label class="single-select-header">
        @if (isTenantSelection()) {
          Tenant:
        } @else {
          Deployment:
        }
      </ion-label>
      @if ((this.isTenantSelection() && this.deploymentStore.tenantList()?.length ?? 0 > 0) || (!this.isTenantSelection() && this.deploymentStore.deploymentList()?.length ?? 0 > 0)) {
        <div id="selected-value" class="single-select-value" slot="end">
          @if (isTenantSelection()) {
            {{ this.deploymentStore.selectedTenant()?.name }}
          } @else {
            {{ this.deploymentStore.selectedDeployment()?.name }}
          }
        </div>
        <div id="selected-value-icon" class="single-select-value" slot="end">
          <ion-icon name="chevron-down-outline"></ion-icon>
        </div>
      } @else {
        <div id="selected-value-not-available" class="single-select-value" slot="end">
          No data available
          @if (!isTenantSelection()) {
            <sup class="ion-padding-start">With configured device & address</sup>
          }
        </div>
      }
    </ion-item>


  </ion-list>
</ng-container>
