import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NotificationStore } from '@/shared/lib/stores/notification.store';
import { addIcons } from 'ionicons';
import { checkmarkDoneOutline, syncOutline } from 'ionicons/icons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ods-loader',
  templateUrl: './ods-loader.component.html',
  styleUrl: './ods-loader.component.scss',
  standalone: true,
  imports: [IonicModule, NgForOf],
})

export class OdsLoaderComponent {
}
