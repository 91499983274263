@if(this.notificationStore.isLoading()) {
  <div class="custom-loading-container">
      <ion-backdrop [visible]="true" />
      <div class="custom-loading-message">
        <ods-loader class="ods-spinner-large"></ods-loader>
        <ion-grid>
            @for (notification of this.notificationStore.notificationData()?.notificationList; track notification ) {
              <ion-row>
                  <ion-col class="ion-text-end">
                      <ion-text style="text-transform: capitalize;">{{notification.description}}</ion-text>
                  </ion-col>
                  <ion-col class="ion-text-start" size="5">
                    @if (notification.isLoaded) {
                      <ion-icon name="checkmark-done-circle-outline" color="success"></ion-icon>
                    } @else {
                      <ods-loader class="ods-spinner-small"></ods-loader>
                    }
                  </ion-col>
              </ion-row>
            }
          </ion-grid>
      </div>
  </div>
}
