import {
  ChangeDetectionStrategy,
  Component,
  computed, EventEmitter,
  inject,
  input,
  Input,
  OnChanges,
  OnInit, Output,
  signal,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DeploymentPortMappings } from '@/app/interfaces/api/deployment-port-mappings';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { Hierarchical } from '@/shared/lib/core/utils/hierarchical';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ods-search-list',
    templateUrl: './search-list.component.html',
    imports: [CoreModule]
})
export class SearchListComponent implements OnInit, OnChanges {
  @Input() isTenantSelection: boolean = true;
  searchData = signal<any[] | null>([]);

  modalController = inject(ModalController);
  deploymentStore = inject(DeploymentStore);

  filteredItems = signal<any[]>([]);

  ngOnChanges() {
    this.searchData.set(this.isTenantSelection ? this.deploymentStore.tenantList() : this.deploymentStore.deploymentList());

    this.searchData()?.forEach(x => x.className = Hierarchical.getLevelClass(x, this.searchData() ?? []));
    this.filteredItems.set(this.searchData() ?? []);
  }

  ngOnInit() {
    this.searchData.set(this.isTenantSelection ? this.deploymentStore.tenantList() : this.deploymentStore.deploymentList());

    this.searchData()?.forEach(x => x.className = Hierarchical.getLevelClass(x, this.searchData() ?? []));
    this.filteredItems.set(this.searchData() ?? []);
  }

  setSelection(item: any) {
    this.modalController.dismiss(item, 'confirm');
  }

  cancelChanges() {
    this.modalController.dismiss(null, 'cancel');
  }

  reloadData() {
    if (this.isTenantSelection) {
      this.deploymentStore.loadTenantList(true);
    } else {
      this.deploymentStore.loadDeploymentList(true);
    }
  }

  searchbarInput(ev: any) {
    this.filterList(ev.target.value);
  }

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredItems.set(this.searchData() ?? []);
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      this.filteredItems.set(this.searchData()?.filter((item) => {
        return item.name.toLowerCase().includes(normalizedQuery) || item.id.toString().includes(normalizedQuery);
      }) ?? []);
    }
  }

  protected readonly isNotNullOrEmpty = isNotNullOrEmpty;
}
