import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

import { storagePrefix } from '@/shared/lib/constants/storage.const';

import { ScanType } from '../../../../app/src/app/constants/scan-type.enum';
import { ActionOtdr } from '../../../../app/src/app/interfaces/action-page/action-otdr';
import { ActionSettings } from '../../../../app/src/app/interfaces/action-page/action-settings';
import { ActionThreshold } from '../../../../app/src/app/interfaces/action-page/action-threshold';
import { ReportDetails } from '../../../../app/src/app/interfaces/scan/report-details';
import { AppInfo } from '@capacitor/app';

type DataState = {
  reportList: ReportDetails[],
  appInfo: AppInfo | null,
  deviceInfo: any
};

const initialState: DataState = {
  reportList: [],
  appInfo: null,
  deviceInfo: null
};

export const DataStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store) => ({
    setReport(report: ReportDetails) {
      const currentList = store.reportList();
      const currentReport = currentList.findIndex(f => f.scanType === report.scanType);

      if (currentReport !== -1) {
        currentList[currentReport] = report;
      } else {
        currentList.push(report);
      }

      patchState(store, { reportList: currentList });
    },
    removeReport(scanType: number) {
      const currentList = store.reportList();
      const currentReport = currentList.findIndex(f => f.scanType === scanType);

      // remove currentReport from array
      if (currentReport !== -1) {
        currentList.splice(currentReport, 1);
      }
      patchState(store, { reportList: currentList });
    },
    getReport(scanType: number) {
      const currentList = store.reportList();
      return currentList.find(f => f.scanType === scanType);
    },
    clear() {
      patchState(store, { reportList: [] });
    },
    setAppInfo(appInfo: AppInfo) {
      patchState(store, { appInfo });
    },
    setDeviceInfo(deviceInfo: any) {
      patchState(store, { deviceInfo });
    },
    getActionFeatures(scanType: number) {
      let actionSettings: ActionSettings;
      let actionThresholds: ActionThreshold[];
      let actionOtdr: ActionOtdr;

      if (scanType === ScanType.Scan) {
        actionSettings = {
          Range: true,
          AverageTime: true,
          WaveLength: true,
          Ior: true,
          Pulse: true
        }

        actionOtdr = {
          SamplingRate: true,
          MeasurementMode: true,
          FiberEndDetection: true,
          LossDetection: true,
          ReflectanceDetection: true,
          LaunchFiberStart: false,
          LaunchFiberEnd: false,
          Wavelength: true,
        }
        actionThresholds = [];
      } else if (scanType === ScanType.VisualFaultLocator) {
        actionSettings = {
          Range: false,
          AverageTime: true,
          WaveLength: false,
          Ior: false,
          Pulse: false
        }

        actionOtdr = new ActionOtdr();
        actionThresholds = [];
      } else if (scanType === ScanType.MonitoringStart) {
        actionSettings = {
          Range: true,
          AverageTime: true,
          WaveLength: true,
          Ior: true,
          Pulse: true
        }

        actionOtdr = {
          SamplingRate: true,
          MeasurementMode: true,
          FiberEndDetection: true,
          LossDetection: true,
          ReflectanceDetection: true,
          LaunchFiberStart: false,
          LaunchFiberEnd: false,
          Wavelength: true,
        }

        actionThresholds = []; // this.actionThresholdsList;
      } else if (scanType === ScanType.ConnectorCheck) {
        actionSettings = {
          Range: false,
          AverageTime: false,
          WaveLength: true,
          Ior: false,
          Pulse: false
        }

        actionOtdr = {
          SamplingRate: false,
          MeasurementMode: false,
          FiberEndDetection: true,
          LossDetection: true,
          ReflectanceDetection: true,
          LaunchFiberStart: false,
          LaunchFiberEnd: false,
          Wavelength: true,
        }
        actionThresholds = [];
      } else {
        actionSettings = new ActionSettings();
        actionOtdr = new ActionOtdr();
        actionThresholds = [];
      }

      return {
        displayActionSettings: actionSettings.Pulse || actionSettings.Ior || actionSettings.Range || actionSettings.AverageTime || actionSettings.WaveLength,
        displayActionOtdr: actionOtdr.LaunchFiberEnd || actionOtdr.LaunchFiberStart || actionOtdr.FiberEndDetection || actionOtdr.LossDetection || actionOtdr.FiberEndDetection || actionOtdr.ReflectanceDetection || actionOtdr.MeasurementMode || actionOtdr.SamplingRate,
        displayActionThresholds: actionThresholds.length > 0,
        actionSettings,
        actionOtdr,
        actionThresholds
      }
    }
  })),
  withStorageSync({
    key: `${storagePrefix}_data`,
    storage: () => sessionStorage,
  })
);
