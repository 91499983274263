import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { apiEndpoint } from '../../constants/endpoints.const';
import { SignalRConnectionInfo } from '../../interfaces/account/signal-r-connection-info';

@Injectable({
    providedIn: 'root'
})

export class SignalRApi {
    http = inject(HttpClient);

    getSignalRToken(): Observable<SignalRConnectionInfo> {
        return this.http.get<SignalRConnectionInfo>(`${apiEndpoint}/signalr/connect/byheader`);
    }
}
