import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scanTypeIcon',
  standalone: true,
})
export class ScanTypeIconExtension implements PipeTransform {
  transform(scanType: number): string {
    switch (scanType) {
      case 1: {
        return 'assets/icon/SVG/scan.svg';
      }
      case 2: {
        return 'assets/icon/SVG/connectorcheck.svg';
      }
      case 3: {
        return 'assets/icon/SVG/aux.svg';
      }
      case 4: {
        return 'assets/icon/SVG/vfl.svg';
      }
      case 99: {
        return 'assets/icon/SVG/stop.svg';
      }
      case 101: {
        return 'assets/icon/SVG/monitoring.svg';
      }
      case 102: {
        return 'assets/icon/SVG/monitoring-stop.svg';
      }
      case 131: {
        return 'assets/icon/SVG/monitoring-reset.svg'
      }
      case 201: {
        return 'assets/icon/SVG/configuration-empty.svg';
      }
      default: {
        return '';
      }
    }
  }
}
