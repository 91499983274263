import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../app/src/environments/environment';
import { NotificationService } from '../../../../../app/src/app/services/communications/notification.service';
import { inject } from '@angular/core';
import { UserStore } from '@/shared/lib/stores/user.store';
import { NotificationType } from '../../../../../app/src/app/constants/notification.enum';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';

export const errorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const notificationService = inject(NotificationService);
  const userStore = inject(UserStore);
  let notificationType: NotificationType = NotificationType.Warning;
  let message: string;

  return next(req).pipe(
    catchError((error) => {
      if (!navigator.onLine) {
        notificationService.presentToast('You are currently not connected to internet', NotificationType.Warning);
      } else {
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error('Error Event');
          } else {
            switch (error.status) {
              case 400:
                if (error.url?.includes(environment.identity.baseUrl)) {
                  message = 'Your account could not be updated.';
                  notificationType = NotificationType.Warning;
                } else {
                  message = 'Bad Request';
                  notificationType = NotificationType.Error;
                }
                break;
              case 401:
                console.error(error);
                userStore.clear();
                message = 'You are not authorized to access this resource.';
                notificationType = NotificationType.Warning;
                break;
              case 403:
                message = 'The app encountered a problem loading some of its data. \nPlease close the app and reopen.\n\nIf the problem persist, contact support.';
                notificationType = NotificationType.Error;
                break;
              case 422:
                message = 'Your request could not be send to the device. It seems that the device is currently offline / unreachable.';
                notificationType = NotificationType.Warning;
                break;
              case 425:
                message = 'This request could not be handled. There is already an active monitoring request for this fiber.';
                notificationType = NotificationType.Warning;
                break;
              default:
                message = 'The app encountered a problem loading some of its data. \nPlease close the app and reopen.\n\nIf the problem persist, contact support.';
                notificationType = NotificationType.Error;
                break;
            }
          }
          // Server error
        } else {
          // Client Error
          if (error.message.includes('Error: Failed to complete negotiation with the server: TypeError: Failed to fetch')) {
            message = 'The `Realtime updates` connection has run in a timeout. It will auto reconnect.';
          } else {
            message = 'We\'ve ran into a problem loading data. \nPlease try to refresh the page. \n\nIf the problem persist, contact support.';
          }

          notificationType = NotificationType.Warning;
        }
      }

      if (isNotNullOrEmpty(message)) {
        notificationService.presentToast(message, notificationType);
      }

      return EMPTY;
    }),
  );
};
