import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ods-loader',
  templateUrl: './ods-loader.component.html',
  styleUrl: './ods-loader.component.scss',
  imports: [IonicModule],
})

export class OdsLoaderComponent {
}
